

  .button__navCell {
    height: 40px;
    /* width: 180px; */
    margin: 0;
    border-radius: 50px;
    border: none;
    outline: none;
    background:linear-gradient(90deg,#48e2b1,#5c7ed3);

    color: #000C75;
    font-weight:600;
    font-size: 16px;
    /* letter-spacing: 1px; */
    /* text-transform: uppercase; */
    cursor: pointer;
    position: relative;

}

.button__navCell:hover{
    background: linear-gradient(90deg,#1DF0A9,#E289E5,#E7C6CA,#827CF3,#9FB7F1,#1DF0A9);
    background-size: 400%;
}

.button__navCell:before{
    content: '';
    position: absolute;
    background: inherit;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border-radius: 50px;
    filter: blur(20px);
    opacity: 0;
    transition: opacity 0.5s;
}

.button__navCell:hover:before{

    opacity: 1;
    z-index: -1;
}
.button__navCell:hover{
    z-index: 1;
    animation: glow 8s linear infinite;
}


.button__lang{
    height: 40px;
    /* width: 180px; */
    margin: 0;
    border-radius: 50px;
    border: none;
    outline: none;
    background:none;

    color: #000C75;
    font-weight:600;
    font-size: 16px;
    /* letter-spacing: 1px; */
    /* text-transform: uppercase; */
    cursor: pointer;
    position: relative;

}